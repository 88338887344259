$(function(){
    $('#rewards .container .flex img').on('click', function(){
        var backGround = $(this).attr('src'); 
        $('.modal-rewards').addClass("show");
        $('.modal-rewards .modal-content img').attr('src', backGround);
    });

    $('.modal-rewards .modal-content .flex i.icofont').on('click', function(){
        $('.modal-rewards').removeClass("show");
    });
});